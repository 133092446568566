import "../common.entrance";

window.onload = function () {
  // SELECT element containing the region options.
  var selectElement = document.getElementById("id_auth-region");

  // Handle region selection and saving to local storage
  if (!selectElement) {
    return;
  }

  var availableRegions = Array.from(selectElement.options).map((option) => ({
    regionId: option.dataset.regionId,
    value: option.value,
  }));

  // Find the region the app is currently running in.
  var currentRegionOption = Array.from(selectElement.options).find(
    (option) => option.getAttribute("data-is-current-region") === "True",
  );
  var currentRegionId = currentRegionOption.dataset.regionId;

  // Find any stored region information in localStorage
  var storedRegionId = localStorage.getItem("selectedRegionId");

  // Find, if availabe, the 'circuit breaker' parameter when forcing a redirect back to a specific region
  var forceRegionReset = new URLSearchParams(window.location.search).get(
    "forceRegionReset",
  );

  // If we have a stored region, that is not the current region, and the forceRegionReset is not active, redirect to the stored region.
  if (
    storedRegionId !== null &&
    storedRegionId !== currentRegionId &&
    forceRegionReset !== "true"
  ) {
    var targetUrl = availableRegions.find(
      (region) => region.regionId === storedRegionId,
    ).value;

    console.log("Redirecting to stored region:", targetUrl);
    if (window.location.href !== targetUrl) {
      window.location = targetUrl;
    }
  } else {
    if (forceRegionReset === "true") {
      localStorage.removeItem("selectedRegionId");
    }
  }

  selectElement.addEventListener("change", function () {
    var selectedOption = this.options[this.selectedIndex];
    var targetRegionId = selectedOption.dataset.regionId;

    // Don't do anything if the current region is selected.
    if (currentRegionId === targetRegionId) return;

    // If the current region is 'nl', store the selection
    if (currentRegionId === "nl") {
      // Save the selected option in localStorage
      localStorage.setItem("selectedRegionId", targetRegionId);
    }

    // Redirect, if we're redirecting back to 'nl', add the circuit breaker URL parameter.
    var regionUrl = new URL(selectedOption.value);

    if (targetRegionId === "nl") {
      // Add the `forceRegionReset` URL parameter
      regionUrl.searchParams.append("forceRegionReset", "true");
    }

    if (window.location !== regionUrl.toString()) {
      window.location.href = regionUrl.toString();
    }
    return;
  });
};
